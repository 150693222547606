<template>
  <div class="project-sales-edit-booking-documents">
    <fd-form @submit="submitForm">
      <modal-content class="card">
        <template #header>
          <h4 class="p-2">Edit Booking Documents</h4>
        </template>
        <template #body>
          <div class="row p-2">
            <div class="col-12 md-col-6">
              <file-uploader
                v-model="form.purchaserIdentityCardOrPassport"
                class="px-1 mb-2"
                label="IC/Passport"
                :maxFile="20"
                :accept="acceptedFileType"
                :allowed="allowedFormat"
                multiple
                required
                :isLoading.sync="isUploading"
              ></file-uploader>
            </div>
            <div class="col-12 md-col-6">
              <file-uploader
                v-model="form.saleForm"
                class="mx-1 mb-2"
                label="Sale Form"
                :accept="acceptedFileType"
                :allowed="allowedFormat"
                :maxFile="10"
                multiple
                required
                :isLoading.sync="isUploading"
              ></file-uploader>
            </div>
          </div>
        </template>

        <template #footer>
          <div class="d-flex justify-content-end p-2">
            <fd-button @click="$emit('close')">Close</fd-button>
            <fd-button type="submit" class="main ml-2" :disabled="isUploading"
              >Save</fd-button
            >
          </div>
        </template>
      </modal-content>
    </fd-form>
  </div>
</template>

<script>
export default {
  components: {
    FileUploader: () => import("@/components/GlobalComponents/FileUploader"),
    ModalContent: () =>
      import("@/components/GlobalComponents/ModalBox/ModalContent")
  },
  mixins: [],
  props: {
    purchaserIdentityCardOrPassport: {
      type: Array,
      default: () => []
    },
    saleForm: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      form: {
        purchaserIdentityCardOrPassport: [],
        saleForm: []
      },

      isUploading: false,
      acceptedFileType:
        "application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/pdf,image/*",
      allowedFormat: ["pdf", "doc", "docx", "odt", "ods", "jpg", "jpeg", "png"]
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.form = this._.cloneDeep({
      purchaserIdentityCardOrPassport: this.purchaserIdentityCardOrPassport,
      saleForm: this.saleForm
    });
  },
  methods: {
    submitForm() {
      this.$emit("save", this.form);
    }
  }
};
</script>

<style lang="scss">
.project-sales-edit-booking-documents {
  height: 100%;
}
</style>
